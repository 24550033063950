const ReadCategorySortMap = {
  episodes: 1,
  locations: 2,
  movements: 3,
  people: 4,
  'pioneer-stories': 5,
  'historical-milestones': 6,
  'the-7-churches': 7,
  empires: 8,
};

export default ReadCategorySortMap;
