import React, { useState, useEffect } from 'react';

const categoryNameMap = {
  'season-1': 'season 1',
  'season-2': 'season 2',
  'season-3': 'season 3',
  blog: 'blog',
};

export default function ReadFilterSlider({ categories, onCatSelected, selectedCat }) {
  const [currentCat, setCurrentCat] = useState(selectedCat);

  useEffect(() => {
    setCurrentCat(selectedCat);
  }, [selectedCat, categories]);

  return (
    <ul className="fil_cat-list d-flex">
      <li
        className={`fil_cat-item mg-r-5 mg-b-5 ${selectedCat === 'all' ? 'active' : ''}`}
        onClick={() => onCatSelected('all')}
      >
        All
      </li>
      {categories && categories.length > 0
        ? categories.map((category, index) => (
            <li
              key={index}
              onClick={() => onCatSelected(category)}
              className={`fil_cat-item mg-r-5 mg-b-5 ${selectedCat === category ? 'active' : ''}`}
            >
              {categoryNameMap[category] || ''}
            </li>
          ))
        : null}
    </ul>
  );
}
