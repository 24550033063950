import { Link, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Loadable from 'react-loadable';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import { normalizeReadCategories } from '../utils/read';
import { ReadSectionSortMap } from '../map/readSectionSortMap';
import ReadCategory from '../components/Read/ReadCategory';
import ReadFilterSlider from '../components/Carousel/ReadFilterSlider';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const loader = () => <></>;

const LoadableBannerCarousel = Loadable({
  loader: () => import('../components/Carousel/ReadTopSlider'),
  loading: loader,
});

const ReadPage = (props) => {
  const {
    data: { bgRead, readCategories, featuredReadCat },
  } = props;
  const normalizedCategories = normalizeReadCategories(readCategories?.nodes || [], true);

  const orderedCategories = Object.keys(normalizedCategories)
    .sort((x, y) => {
      return ReadSectionSortMap[x] - ReadSectionSortMap[y];
    })
    .reduce((obj, key) => {
      obj[key] = normalizedCategories[key];

      return obj;
    }, {});

  const [selectedCat, setSelectedCat] = useState('all');
  const featuredReads = featuredReadCat?.nodes || [];

  // FIXME: Not sure about the purpose of this. Commenting right now to see if we need it.
  // if (props['*']) return <ReadPageBySlug slug={props['*']} />;

  return (
    <BodyClassName className="body-light page-read">
      <DefaultLayoutComponent title="Read">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage bg-pos-center-top"
            fluid={bgRead.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm la-pn">
              <div className="carousel pd-x-sm la-pn slideshow-container">
                <LoadableBannerCarousel reads={featuredReads} />
              </div>
            </div>
          </BackgroundImage>
          {
            <section className="categories-watch categories-filters bg-dark">
              <div className="wrapper wrapper-lg pd-x-md">
                <div className="filter-categories">
                  <ReadFilterSlider
                    categories={Object.keys(orderedCategories)}
                    onCatSelected={setSelectedCat}
                    selectedCat={selectedCat}
                  />
                </div>
                <div className="cat_watch-list">
                  {!!orderedCategories &&
                    Object.keys(orderedCategories)
                      .filter((elm) => {
                        if (selectedCat !== 'all') {
                          return selectedCat === elm;
                        }

                        return true;
                      })
                      .map((categoryTitle, index) => (
                        <ReadCategory
                          key={index}
                          categoryTitle={categoryTitle}
                          category={orderedCategories[categoryTitle]}
                        />
                      ))}
                </div>
              </div>
            </section>
          }
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  query ReadPageQuery {
    bgRead: file(relativePath: { eq: "bg-read.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    featuredReadCat: allPrismicRead(
      filter: { data: { read_categories: { elemMatch: { read_category: { slug: { eq: "featured" } } } } } }
    ) {
      nodes {
        data {
          title {
            text
          }

          readCategories: read_categories {
            readCategory: read_category {
              featuredCategory: slug
            }
          }

          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }

          readDuration: read_duration1 {
            text
          }

          featuredVideo: featured_video {
            text
          }
        }
        url
      }
    }

    readCategories: allPrismicRead(
      filter: {
        data: {
          read_categories: {
            elemMatch: { read_category: { slug: { in: ["season-1", "season-2", "season-3", "blog"] } } }
          }
        }
      }
      sort: { fields: data___priority }
    ) {
      nodes {
        data {
          title {
            text
          }
          readCategories: read_categories {
            readCategory: read_category {
              featuredCategory: slug
              id
            }
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
          readDuration: read_duration1 {
            text
          }

          featuredVideo: featured_video {
            text
          }
          priority
        }
        url
        id
      }
    }
  }
`;

export default ReadPage;
