import { Link } from 'gatsby';

import React from 'react';
import Loadable from 'react-loadable';

import ReadItem from './ReadItem';
import ReadCategorySortMap from '../../map/readCategorySortMap';

const loader = () => <div>Loading...</div>;

const LoadableCarousel = Loadable({
  loader: () => import('../Carousel/CarouselSlider'),
  loading: loader,
});

const categoryNameToUrlMap = {
  'season-1': 'season1',
  'season-2': 'season2',
  'season-3': 'season3',
};

export default function ReadCategory(props) {
  let { category, categoryTitle } = props;

  const orderedCategories = Object.keys(category)
    .sort((x, y) => {
      return ReadCategorySortMap[x] - ReadCategorySortMap[y];
    })
    .reduce((obj, key) => {
      obj[key] = category[key];

      return obj;
    }, {});

  const removecategoryTitleHyphen = (categoryTitle) => {
    return categoryTitle.replace(/-/g, ' ');
  };

  if (!category) {
    return null;
  }

  if (Array.isArray(category)) {
    const exploreAllLink = `/read/${categoryTitle}`;

    return (
      <div className="cat_watch-row" style={{ display: 'inherit' }}>
        <div className="cat_watch-body">
          <div className="cat_watch-body-list">
            <div className="cat_watch-title d-flex">
              <div className="cat_watch-head cat-title">{removecategoryTitleHyphen(categoryTitle)}</div>
            </div>
            <div className="cat_watch-item cat_layout-carousel">
              <LoadableCarousel
                className="watch_item-row d-flex owl-carousel la-landscape"
                responsiveClass
                margin={10}
                responsive={{
                  0: {
                    items: 1,
                    margin: 15,
                    nav: false,
                    stagePadding: 40,
                  },
                  576: {
                    items: 2,
                    margin: 10,
                    nav: false,
                    stagePadding: 40,
                  },
                  768: {
                    items: 4,
                    nav: false,
                    loop: false,
                  },
                  1200: {
                    items: 4,
                    nav: true,
                    loop: false,
                  },
                }}
              >
                {!!category.length &&
                  category?.map((elm, index) => (
                    <div key={index} className="watch_item-list">
                      <ReadItem item="carousel" read={elm} />
                    </div>
                  ))}
              </LoadableCarousel>
            </div>
            <div className="cat_watch-link">
              <Link to={exploreAllLink} className="cat-link cat-link-light">
                Explore All
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cat_watch-row" style={{ display: 'inherit' }}>
      {<div className="cat_watch-head cat-title">{removecategoryTitleHyphen(categoryTitle)}</div>}
      <div className="cat_watch-body">
        {Object.keys(orderedCategories)?.map((categoryName, index) => {
          const exploreAllLink = `/read/${categoryNameToUrlMap[categoryTitle] || ''}/${categoryName}`;

          const removecategoryNameHyphen = (categoryName) => {
            return categoryName.replace(/-/g, ' ');
          };

          return (
            <div className="cat_watch-body-list" key={index}>
              {!!categoryName ? (
                <div className="cat_watch-title d-flex">
                  <span className="watch_season-title">{removecategoryNameHyphen(categoryName)}</span>
                </div>
              ) : (
                <div className="cat_watch-head cat-title">{removecategoryNameHyphen(categoryName)}</div>
              )}
              <div className="cat_watch-item cat_layout-carousel">
                <LoadableCarousel
                  key={categoryName + categoryTitle}
                  className="watch_item-row d-flex owl-carousel la-landscape"
                  responsiveClass
                  margin={10}
                  responsive={{
                    0: {
                      items: 1,
                      margin: 15,
                      nav: false,
                      stagePadding: 40,
                    },
                    576: {
                      items: 2,
                      margin: 10,
                      nav: false,
                      stagePadding: 40,
                    },
                    768: {
                      items: 4,
                      nav: false,
                      loop: false,
                    },
                    1200: {
                      items: 4,
                      nav: true,
                      loop: false,
                    },
                  }}
                >
                  {!!orderedCategories[categoryName] &&
                    orderedCategories[categoryName]?.map((categoryDetails, index) => (
                      <div key={index} className="watch_item-list">
                        <ReadItem item="carousel" read={categoryDetails} />
                      </div>
                    ))}
                </LoadableCarousel>
              </div>
              <div className="cat_watch-link">
                <Link to={exploreAllLink} className="cat-link cat-link-light">
                  Explore All
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
