import { Link } from 'gatsby';
import React from 'react';

import { titleCase } from '../../utils/title-case';
import iconRead from '../../images/icon-bars-alt.svg';

export default function ReadItem({ item, read, isSecond, className }) {
  const { featuredImage, readDuration, title, url } = read;
  const imageUrl = featuredImage?.fluid?.src || featuredImage.url || '';

  return (
    <div className={`${item === 'col2' ? `cols-inner-item item-in-in-${isSecond ? `2` : '1'}` : className}`}>
      <Link to={url}>
        <div
          className={` ${item === 'col1' ? 'media ratio_4-3' : ''}
          ${item === 'col2' ? 'media' : ''}
          ${item === 'carousel' ? 'watch_item-figure media ratio_16-9' : ''}`}
        >
          <div className="m_overlay" />
          <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${imageUrl})` }} />
          <div className="media-content">
            {item !== 'carousel' ? <h5 className="media-title h-clamp-line-2">{titleCase(title.text)}</h5> : ''}
            <div className="btn btn-read btn-transparent">
              <img src={iconRead} alt="Icon Read" className="icon-read" />
              <span>Read</span>
            </div>
          </div>
          {
            <div className="m_run-time m_run-time-outer">
              <span>{Math.round(readDuration?.text) ?? readDuration?.text.match(/(\d+)/)[0] ?? '?'} Min Read</span>
            </div>
          }
          <div className="m_border" />
        </div>
        {item === 'carousel' ? <span className="watch_item-title">{titleCase(title.text)}</span> : null}
      </Link>
    </div>
  );
}
